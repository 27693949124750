import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import HeaderPage from '../../utils/HeaderPage';
import SideBar from '../../components/SideBar/SideBar';
import Parser from 'react-html-parser'
import { isSSR, updatePageContent } from '../../utils/utils';
import { submitActiveCampaignForm } from '../../utils/campaign';

const Amazon = (props) => {
  // console.log(props)
  // const AMAZON_PATENTS = props.data.AMAZON_PATENTS.nodes;
  const common = props.data.page;
  const canonicalUrl = `/companies/alphabet`;
  const seo = {};
  //
  useEffect(() => {
    // load OptinMonster
    if (window.om210904_195537) {
      window.om210904_195537.reset();
    }
    // 
    document.addEventListener('om.Campaign.parseCampaigns', function (event) {
      const email = document.getElementById('eminence-field-email').value;
      // submit campaign
      if (email) {
        const data = { name: email, email: email };
        submitActiveCampaignForm(data, false);
      }
    });
  });
  return (
    <Layout>
      <Seo title={`Top Amazon Patents `} canonical={canonicalUrl} seo={seo} />
      <HeaderPage headerTxt={common.title}></HeaderPage>
      <section className='mainSpacing2'>
        <div className="container industries-outr ps-0">
          <div className='row'>
            <div className='col-md-9'>
              <div className='mb-5'>
                <article>
                  <>
                    <div className="accordion-content">
                      {Parser(isSSR() ? common.content : updatePageContent(common.content))}
                    </div>
                  </>
                </article>
              </div>
            </div>
            <div className='col-md-3'>
              <SideBar></SideBar>
            </div>
          </div>
        </div>
        <div className="container">
          <div className='row'>
            <div className='col-md-12 text-center'>
              <p><small>
                Also checkout the latest patents on <Link to="/companies/amazon">Amazon</Link>, <Link to="/companies/apple">Apple</Link>, <Link to="/companies/alphabet">Google/Alphabet</Link>,
                &nbsp;<Link to="/companies/meta">Meta/Facebook</Link>,  <Link to="/companies/microsoft">Microsoft</Link>,
                &nbsp;<Link to="/companies/samsung">Samsung</Link>
              </small>
              </p>
            </div>
          </div>

          <div className="mb-5"></div>
        </div>
      </section>

    </Layout>
  )
}

export const query = graphql`
  query {
    page: wpPage(id: {eq: "cG9zdDozOTkyNQ=="}) {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
`

export default Amazon;
